export default {
    methods: {
        getLineItemDimensions(lineItem) {
            const dimensions = lineItem.variant.dimensions_properties || [{}]
            
            const predefinedAggregation = dimensions.find(x => x.name === 'dimensions')

            if (predefinedAggregation) {
                return predefinedAggregation.value
            }

            const dimensionsProperties = dimensions.filter(x => ['height', 'width', 'depth', 'length'].includes(x.name))
                                                   .slice(0, 3)
                                                   .map(x => x.value)

            return dimensionsProperties.join(' x ')
        }
    }
}
