import {replace} from 'lodash'

export default {
    methods: {
        formatPrice(price, abs = false) {
            let _price = price
            if(!_price) _price = ''

            let re = new RegExp('^-')
            let formatted_price = _price.toString()

            if(abs) formatted_price = formatted_price.replace(re, "")

            let new_price = this.$currency_format.format(formatted_price.replace(re, ""))
            return `${new_price}`
            // return re.test(formatted_price) ? `-${new_price}` : `${new_price}`
        },
        formatPriceRange(range, abs = false) {
            if (Array.isArray(range)) {
                return range.map(price => this.formatPrice(price, abs)).join(' - ')
            }

            return this.formatPrice(range, abs)
        },
        validateInputPrice (event) {
            const value = parseFloat(replace(event.target.value, ',', '.'))
            event.target.value = isNaN(value) ? '0.00' : value.toFixed(2)
        },
        priceWithSign (price, showPositive = true) {
            let sign = ''
            if (Math.abs(price) == 0) {
                sign = ''
            } else {
                if (price > 0) {
                    sign = showPositive ? '+' : ''
                } else {
                    sign = '-'
                }
            }

            return `${sign}${this.formatPrice(Math.abs(price))}`
        },
        getFormatedPrice (price) {
            return price ? this.formatPrice(price) : '-'
        }
    }
}